import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import EventCard from '../../components/store/EventCard';
import { getEvents } from '../../redux/actions/eventActions';
import { getStore } from '../../redux/actions/storeActions';
import ProductCard from '../../components/store/ProductCard';

const EventsCarousel = () => {
  const dispatch = useDispatch();
  const {
    events,
    loading: loadingEvents,
    error: errorEvents,
  } = useSelector((state) => state.events);
  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = useSelector((state) => state.store);

  useEffect(() => {}, [dispatch]);
  useEffect(() => {
    dispatch(getStore());
    dispatch(getEvents());
  }, [dispatch]);

  const renderEvents = (events) => {
    return events.map((event) => {
      return <EventCard key={event.id} event={event} />;
    });
  };

  const renderProducts = (products) => {
    return products
      .filter((product) => {
        return product?.linea?.toLowerCase().includes('biglietti');
      })
      .map((product) => {
        return <ProductCard key={product.id} product={product} />;
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop2: {
      breakpoint: { max: 2000, min: 1500 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1216 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1216, min: 894 },
      items: 3,
    },
    mobile2: {
      breakpoint: { max: 894, min: 587 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 587, min: 0 },
      items: 1,
    },
  };

  return (
    <section className='productsCarousel'>
      <div className='products'>
        {errorEvents && <h2 className='appError'>{errorEvents}</h2>}
        {errorProducts && <h2 className='appError'>{errorProducts}</h2>}
        {loadingEvents && <div className='spinner' />}
        {loadingProducts && <div className='spinner' />}
        <Carousel
          className='carousel'
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}>
          {events && renderEvents(events)}
          {products && renderProducts(products)}
        </Carousel>
      </div>
    </section>
  );
};

export default EventsCarousel;
