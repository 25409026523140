export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';

export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';

export const CREATE_INSURANCE_REQUEST = 'CREATE_INSURANCE_REQUEST';
export const CREATE_INSURANCE_SUCCESS = 'CREATE_INSURANCE_SUCCESS';
export const CREATE_INSURANCE_FAILED = 'CREATE_INSURANCE_FAILED';

export const CLEAR_INSURANCE_REQUEST = 'CLEAR_INSURANCE_REQUEST';
export const CLEAR_INSURANCE_SUCCESS = 'CLEAR_INSURANCE_SUCCESS';
export const CLEAR_INSURANCE_FAILED = 'CLEAR_INSURANCE_FAILED';

export const REMOVE_TICKET_REQUEST = 'REMOVE_TICKET_REQUEST';
export const REMOVE_TICKET_SUCCESS = 'REMOVE_TICKET_SUCCESS';
export const REMOVE_TICKET_FAILED = 'REMOVE_TICKET_FAILED';

export const ATHLETE_INFO_REQUEST = 'ATHLETE_INFO_REQUEST';
export const ATHLETE_INFO_SUCCESS = 'ATHLETE_INFO_SUCCESS';
export const ATHLETE_INFO_FAILED = 'ATHLETE_INFO_FAILED';

export const CLEAR_ATHLETE_INFO_REQUEST = 'CLEAR_ATHLETE_INFO_REQUEST';
export const CLEAR_ATHLETE_INFO_SUCCESS = 'CLEAR_ATHLETE_INFO_SUCCESS';
export const CLEAR_ATHLETE_INFO_FAILED = 'CLEAR_ATHLETE_INFO_FAILED';

export const ADMIN_EVENTS_INFO_REQUEST = 'ADMIN_EVENTS_INFO_REQUEST';
export const ADMIN_EVENTS_INFO_SUCCESS = 'ADMIN_EVENTS_INFO_SUCCESS';
export const ADMIN_EVENTS_INFO_FAILED = 'ADMIN_EVENTS_INFO_FAILED';

export const ADMIN_GET_SPECTATOR_TICKET_REQUEST =
  'ADMIN_GET_SPECTATOR_TICKET_REQUEST';
export const ADMIN_GET_SPECTATOR_TICKET_SUCCESS =
  'ADMIN_GET_SPECTATOR_TICKET_SUCCESS';
export const ADMIN_GET_SPECTATOR_TICKET_FAILED =
  'ADMIN_GET_SPECTATOR_TICKET_FAILED';
