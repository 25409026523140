// Create event reducers following storeReducer pattern
// Import event action types
import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILED,
  // GET EVENT
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILED,
  // CREATE INSURANCE
  CREATE_INSURANCE_REQUEST,
  CREATE_INSURANCE_SUCCESS,
  CREATE_INSURANCE_FAILED,
  // CLEAR INSURANCE
  CLEAR_INSURANCE_REQUEST,
  CLEAR_INSURANCE_SUCCESS,
  CLEAR_INSURANCE_FAILED,
  ATHLETE_INFO_REQUEST,
  ATHLETE_INFO_SUCCESS,
  ATHLETE_INFO_FAILED,
  CLEAR_ATHLETE_INFO_REQUEST,
  CLEAR_ATHLETE_INFO_SUCCESS,
  CLEAR_ATHLETE_INFO_FAILED,
  ADMIN_EVENTS_INFO_REQUEST,
  ADMIN_EVENTS_INFO_FAILED,
  ADMIN_EVENTS_INFO_SUCCESS,
  ADMIN_GET_SPECTATOR_TICKET_REQUEST,
  ADMIN_GET_SPECTATOR_TICKET_SUCCESS,
  ADMIN_GET_SPECTATOR_TICKET_FAILED,
} from '../../constants/eventCostants';

export const eventsReducer = (state = { events: [] }, action) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return { loading: true, ...state };

    case GET_EVENTS_SUCCESS:
      return { loading: false, events: action.payload };

    case GET_EVENTS_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EVENT_REQUEST:
      return { loading: true };

    case GET_EVENT_SUCCESS:
      return { loading: false, event: action.payload };

    case GET_EVENT_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const createInsuranceReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_INSURANCE_REQUEST:
    case CLEAR_INSURANCE_REQUEST:
      return { loading: true };

    case CREATE_INSURANCE_SUCCESS:
    case CLEAR_INSURANCE_SUCCESS:
      return { loading: false, insurance: action.payload };

    case CREATE_INSURANCE_FAILED:
    case CLEAR_INSURANCE_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const athleteInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case ATHLETE_INFO_REQUEST:
    case CLEAR_ATHLETE_INFO_REQUEST:
      return { loading: true };

    case ATHLETE_INFO_SUCCESS:
    case CLEAR_ATHLETE_INFO_SUCCESS:
      return { loading: false, data: action.payload };

    case ATHLETE_INFO_FAILED:
    case CLEAR_ATHLETE_INFO_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const adminEventsReducer = (state = { events: [] }, action) => {
  switch (action.type) {
    case ADMIN_EVENTS_INFO_REQUEST:
      return { loading: true, ...state };

    case ADMIN_EVENTS_INFO_SUCCESS:
      return { loading: false, events: action.payload };

    case ADMIN_EVENTS_INFO_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const adminSpectatorTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_SPECTATOR_TICKET_REQUEST:
      return { loading: true, ...state };

    case ADMIN_GET_SPECTATOR_TICKET_SUCCESS:
      return { loading: false, ticket: action.payload };

    case ADMIN_GET_SPECTATOR_TICKET_FAILED:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
